.main {
    background-image: url(https://images.rbxcdn.com/782b7fc18a24ee997efd9a7f02fa4bf9-bg_08072019.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    height: 100%;
    width: 100%;
    font-family: HCo Gotham SSm, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

.login-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    margin: auto;
    border-radius: 8px;
    font-weight: 500;
    min-width: 80px;
    width: 100px;
    max-height: fit-content;
    max-width: 140px;
    border-color: #fff;
    z-index: 1;
}

.home-logo {
    z-index: 1;
    /* border: 3px red solid; */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.profile-darken {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    /* top: 0; */
}

@media (max-width:500px) {
    .login-btn {
        /* top: 5px;
        right: 5px; */
    }
}